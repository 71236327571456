
main.main-privacy-policy {

  div.privacy-policy-content {
    ul {
      margin-left: 2rem;
    }

    p, div, li {
      font-size: 0.95rem;
    }

    h2, h3 {
      margin-top: 2rem;
    }
  }
}

@import '../../style/Variables';

footer.main-footer {
  justify-content: space-evenly;
  background-color: $footer-background-color;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  height: $footer-height;

  box-shadow: $footer-box-shadow;

  div.footer-col-left {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
    width: 33%;

    img {
      filter: brightness(0) invert(1);
      height: $footer-img-height;
    }
  }
  div.footer-col-right {
    align-items: center;
    display: flex;
    height: 100%;
    width: 66%;
    justify-content: space-around;

    div.footer-copyright {
      align-items: center;
      justify-content: center;
      color: $footer-copyright-color;
      display: flex;
      height: 100%;
      width: 33%;
      text-align: center;
    }

    div.footer-links {
      a {
        color: $footer-links-color;
        display: block;
        margin: 2px 0;

        &:hover {
          color: $footer-links-color-hover;
        }
      }

      flex-direction: column;
      width: 33%;
      height: 100%;
      align-items: flex-start;
      justify-content: center;
      display: flex;
    }
  }
}

@media screen and (max-width: 507px) {
  footer.main-footer {
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
    height: auto;
  }

  footer.main-footer div.footer-col-right div.footer-copyright {
    margin-top: 30px;
    width: 100%;
  }

  footer.main-footer div.footer-col-right {
    margin-top: 30px;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }
}


@mixin button($color, $background-color) {
  background-color: $background-color;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  color: $color;
  font-size: 18px;
  transition: filter 0.5s ease;

  &:disabled {
    opacity: 0.3;
  }

  &:not(:disabled):hover {
    cursor: pointer;
    filter: brightness(130%);
  }
}

@mixin button-lg($-color, $-background-color) {
  @include button($-color, $-background-color);
  padding: 16px 32px;
  font-size: 30px;
  font-weight: 700;
}

@mixin divider($-top, $-color) {
  position: relative;

  &::before {
    content: "";
    border: solid 2px $-color;
    border-radius: 2px;
    position: absolute;
    margin-left: -50px;
    width: 100px;
    top: $-top;
    left: 50%;
  }
}

@import '../../style/Mixins';

main.main-front-page {
  div.feature-patient-app-badges {
    margin: 1rem auto 1rem auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .apple-app-store-badge {
      margin-left: 14px;
      height: 60px;
    }
    .google-play-badge {
      height: 88px;
    }
  }

  .main-feature-patient-app .main-feature-image {
    max-width: 1000px;
    padding-top: 20px;
  }

  button.main-contact-us-btn {
    display: block;
    margin: 0 auto 4rem;
    @include button-lg(white, #0066b2);
  }
}

@import '../../../style/Variables';
@import '../../../style/Mixins';

section.main-feature {
  display: flex;
  margin-top: 4rem;
  margin-bottom: 4rem;
  justify-content: center;
  align-items: center;

  &.main-feature-stacked {
    flex-direction: column !important;

    .main-feature-text {
      width: 95%;
      max-width: 800px;
    }

    .main-feature-image {
      width: 95%;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  &:not(.main-feature-stacked) {
    &.main-feature-swapped {
      flex-direction: row-reverse;

      .main-feature-image {
        margin: 0 4rem 0 0;
      }
    }

    .main-feature-text {
      width: 45%;
    }

    .main-feature-image {
      width: 55%;
      margin: 0 0 0 4rem;
    }
  }

  .main-feature-title {
    color: #213760;
  }

  .main-feature-description {
    max-width: 1000px;
    width: 100%;
  }

  .main-feature-image img {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 1400px) {
  section.main-feature {
    flex-direction: column !important;

    .main-feature-text {
      width: 95% !important;
      max-width: 800px !important;
    }

    .main-feature-image {
      width: 95% !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
}

@import '../../style/Variables';
@import '../../style/Mixins';

header.main-header {
  height: $header-height;
  &.tall { height: $header-height-tall; }

  background: $header-background-color;

  display: flex;
  flex-direction: row;
  align-items: center;

  div.main-header-brand img {
    height: $header-img-height;
  }

  nav.main-header-nav {
    flex-grow: 1;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    a, button {
      margin-left: 2.6rem;
      font-weight: 500;
      transition: all 0.3s ease;
      color: $header-links-color;
      font-size: 18px;
    }

    a:hover {
      color: $header-links-color-hover;
    }

    button.main-header-sign-in {
      @include button($header-sign-in-button-color, $header-sign-in-button-background-color);
    }
  }
}

@import '../../style/Mixins';

.sign-in-modal {
  div.modal-header {
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #d0d0d0;

    h1 {
      font-size: 1.8rem;
      font-weight: normal;
      color: #444;
    }
  }

  div.modal-body {
    margin: 0 2rem;

    div.invalid-sign-in-alert {
      display: flex;
      align-items: center;
      min-height: 40px;
      margin: 1rem 0 1rem 0;
      opacity: 0;
      transition: opacity 0.25s ease;

      &.visible {
        opacity: 1;
      }

      div.sfyg-svg-icon {
        flex-shrink: 0;
        transform: translateY(-1px);

        svg path {
          fill: #dc3545;
        }
      }

      span {
        margin-left: 0.4rem;
        font-size: 14px;
        color: #dc3545;
      }
    }

    label {
      display: inline-block;
      margin-top: 0.5rem;
      margin-bottom: .25rem;
    }

    .form-control {
      margin: 0 0 1rem 0;
      display: block;
      width: calc(100% - 1.5rem);
      height: calc(1.5rem + 2px);
      padding: .375rem .75rem;
      font-size: 1rem;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;

      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

      border-radius: 5px;
      border: 1.3px solid #d0d0d0;

      &.is-invalid {
        border-color: #dc3545;

        &:focus {
          outline-color: #dc354599;
        }
      }
    }
  }

  div.modal-footer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 2rem 1rem;

    button.sign-in-submit {
      @include button(white, #0066b2);
    }

    a.sign-in-password-reset {
      font-size: 16px;
    }
  }
}



.sign-in-modal {
  position: absolute;
  margin: auto;
  max-width: 480px;
  top: 40px;
  left: 40px;
  right: 40px;

  border: 1px solid #d0d0d0;
  background: white;
  overflow: auto;
  border-radius: 4px;
  outline: none;
}

.sign-in-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}



.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

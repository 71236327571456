@import 'style/Variables';

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Montserrat', sans-serif;
  background: $body-background;
}

h1,
h2,
h3,
ul {
  padding: 0;
  margin: 0;
}

h2 {
  margin: 0;
  font-weight: 700;
  font-size: 28px;
}

p, 
li {
  font-size: 20px;
  font-weight: 500;
}

li {
  margin: 1em 1.3em;
}

a {
  text-decoration: none;
  color: $link-color;

  a:hover {
    color: $link-color-hover;
    text-decoration: underline;
  }

  &:visited {
    color: inherit;
  }
}

.default-margins-y {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.default-margins-x {
  margin-left: 8rem;
  margin-right: 8rem;
}

.default-padding-y {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.default-padding-x {
  padding-left: 8rem;
  padding-right: 8rem;
}

@media screen and (max-width: 1200px) {
  .default-margins-x {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .default-padding-x {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media screen and (max-width: 500px) {
  .default-margins-x {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .default-padding-x {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

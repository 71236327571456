
button.sfyg-icon-btn {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.sfyg-svg-icon {
  position: relative;
  clear: both;

  div.sfyg-svg-icon-positioning {
    padding: 0 0 100% 0;
  }

  svg {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;

    path {
      fill: #000;
      color-interpolation-filters: linearRGB;
      color-interpolation: sRGB;
      color-rendering: auto;
      image-rendering: auto;
      isolation: auto;
      letter-spacing: normal;
      line-height: normal;
      mix-blend-mode: normal;
      shape-padding: 0;
      shape-rendering: auto;
      solid-color:#000;
      solid-opacity: 1;
      stroke-linejoin: round;
      stroke-width: 16;
      vector-effect: none;

      // Match the transition used by bootstrap buttons.
      transition: fill .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
  }
}

@import "../../style/StripUnits";
@import "SpinnerVariables";

.spinner {

  &, div {
    pointer-events: none;
    outline: none;
    box-shadow: none;
  }



  @keyframes lds-dual-ring {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes lds-dual-ring {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .lds-dual-ring {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .lds-dual-ring div {
    box-sizing: border-box;
  }

  .lds-dual-ring > div {
    position: absolute;
    width: 180px;
    height: 180px;
    top: 10px;
    left: 10px;
    border-radius: 50%;
    border: 20px solid #000;
    border-color: #ffffff transparent #ffffff transparent;
    -webkit-animation: lds-dual-ring 0.7s linear infinite;
    animation: lds-dual-ring 0.7s linear infinite;
  }

  .lds-dual-ring > div:nth-child(2) {
    border-color: transparent;
  }

  .lds-dual-ring > div:nth-child(2) div {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .lds-dual-ring > div:nth-child(2) div:before,
  .lds-dual-ring > div:nth-child(2) div:after {
    content: "";
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: -20px;
    left: 60px;
    background: #ffffff;
    border-radius: 50%;
    box-shadow: 0 160px 0 0 #ffffff;
  }

  .lds-dual-ring > div:nth-child(2) div:after {
    left: -20px;
    top: 60px;
    box-shadow: 160px 0 0 0 #ffffff;
  }

  .lds-dual-ring {
    width: $spinner-size !important;
    height: $spinner-size !important;
    -webkit-transform: translate(-$spinner-size / 2, -$spinner-size / 2) scale(strip-units($spinner-size / 2) / 100) translate($spinner-size / 2, $spinner-size / 2);
    transform: translate(-$spinner-size / 2, -$spinner-size / 2) scale(strip-units($spinner-size / 2) / 100) translate($spinner-size / 2, $spinner-size / 2);
  }
}

@import '../../../style/Variables';
@import '../../../style/Mixins';

div.main-hero {
  margin: 0;
  padding: 8rem 0;

  width: 100%;
  min-height: calc(100vh - #{$header-height-tall} - 16rem);

  background: $main-hero-background-color;
  color: $main-hero-color;

  display: flex;
  align-items: center;
  justify-content: center;

  div.main-hero-inner {
    max-width: 1800px;
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  div.main-hero-col-left {
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 700px;
    width: 100%;

    img {
      width: 500px;
    }
    p {
      line-height: 1.3;
      margin-left: 2rem;
      max-width: 600px;
      font-weight: 600;
      font-size: 20px;
      width: calc(100% - 2rem);
    }
    button {
      @include button-lg($header-learn-more-button-color, $header-learn-more-button-background-color);
      margin-top: 80px;
    }
  }

  div.main-hero-col-right {
    // max-height: 471px;
    max-width: 819px;
    img {
      // height: 100%;
      width: 100%;
    }
  }
}

@media screen and (max-width: 1400px) {
  div.main-hero div.main-hero-inner {
    flex-direction: column;
    align-items: center;

    div.main-hero-col-right img {
      margin-top: 4rem;
      width: 95%;
    }

  }
}

@media screen and (max-width: 600px) {
  div.main-hero div.main-hero-inner div.main-hero-col-left img {
    width: 95%;
  }
}

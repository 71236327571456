@import '../../style/Mixins';

main.main-password-reset {

  div.password-reset-alert {
    max-width: 500px;
    margin: 1rem 0;

    padding: .75rem 1.25rem;
    border: 1px solid transparent;
    border-radius: .25rem;

    h2 {
      font-size: 20px;
      font-weight: 600;
    }

    p {
      font-size: 16px;
    }

    a {
      color: inherit;
      font-weight: 600;
    }

    &.password-reset-alert-success {
      background-color: #d4edda;
      border-color: #c3e6cb;
      color: #155724;
    }

    &.password-reset-alert-error {
      background-color: #f8d7da;
      border-color: #f5c6cb;
      color: #721c24;
    }

  }

  form {
    max-width: 500px;
  }

  label {
    display: inline-block;
    margin-top: 0.5rem;
    margin-bottom: .25rem;
  }

  .form-control {
    margin: 0 0 0 0;
    display: block;
    width: calc(100% - 1.5rem);
    height: calc(1.5rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;

    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    border-radius: 5px;
    border: 1.3px solid #d0d0d0;

    &.is-invalid {
      border-color: #dc3545;

      &:focus {
        outline-color: #dc354599;
      }
    }
  }

  div.invalid-password-reset-alert {
    display: none;
    align-items: center;
    min-height: 40px;
    margin: 0.25rem 0 0 0;
    opacity: 0;
    transition: opacity 0.5s ease;

    &.visible {
      display: flex;
      opacity: 1;
    }

    div.sfyg-svg-icon {
      flex-shrink: 0;
      transform: translateY(-1px);

      svg path {
        fill: #dc3545;
      }
    }

    span {
      margin-left: 0.4rem;
      font-size: 14px;
      color: #dc3545;
    }
  }

  button.password-reset-submit {
    @include button(white, #0066b2);
    margin-top: 1rem;
  }

}

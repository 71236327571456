div.scaffold {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  min-height: 100vh;

  div.scaffold-header { }

  div.scaffold-main {
    flex: 1;
  }

  div.scaffold-footer { }
}

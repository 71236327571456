$red-900: #440011;
$red-800: #800020;

$red-200: #ff0040;

$gray-900: #161616;
$gray-800: #252525;
$gray-700: #373737;
$gray-400: #4b4c54;
$gray-300: #616161;
$gray-200: #a7a7a7;



$padding-x: 130px;
$padding-x-md: 80px;
$padding-x-sm: 50px;

$max-width: 1920px;


// Sphygmo
// $primary: #440011;
// $secondary: #21ac41;
// $secondary-dark: #00440f;

// A&D
$primary: #68c8c6;
$primary-dark: darken($primary, 10%);
$secondary: #0066b2;
// $secondary-dark: #001b37;
$secondary-dark: darken($secondary, 10%);

$body-background: url('/img/bg-scatter.png'), #eff1f3 !important;

$link-color: #0054bf;
$link-color-hover: white;

$header-height: 50px;
$header-img-height: 33px;
$header-height-tall: 80px;
$header-background-color: $primary-dark;
$header-links-color: #212121;
$header-links-color-hover: white;
$header-sign-in-button-background-color: $secondary;
$header-sign-in-button-color: #fff;

$main-hero-background-color: linear-gradient(to bottom, $primary-dark, $primary);
$main-hero-color: #212121;
$header-learn-more-button-background-color: $secondary;
$header-learn-more-button-color: #fff;

$support-reset-password-button-background-color: #0066b2;
$support-reset-password-button-color: white;

$footer-height: 140px;
$footer-img-height: 60px;
$footer-background-color: $gray-700;
$footer-box-shadow: inset 0 3px 15px -5px rgba(0, 0, 0, 0.16);
$footer-copyright-color: $gray-200;
$footer-links-color: $gray-200;
$footer-links-color-hover: white;
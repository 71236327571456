@import '../../style/Variables';
@import '../../style/Mixins';

main.main-support {

  h3 {
    margin-top: 2rem;
  }

  button.password-reset-btn {
    @include button($support-reset-password-button-background-color, $support-reset-password-button-color);
    // margin-left: 1rem;
  }
}


button.sfyg-spinner-btn {
  position: relative;

  div.sfyg-spinner-btn-label {
    transition: opacity 0.3s ease-in-out;
  }

  div.sfyg-spinner-btn-spinner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: hidden;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: opacity 0.3s ease-in-out;
  }

  &.sfyg-spinner-btn-not-loaded {
    div.sfyg-spinner-btn-label {
      opacity: 1.0;
    }

    div.sfyg-spinner-btn-spinner {
      opacity: 0.0;
    }
  }

  &.sfyg-spinner-btn-loading {
    div.sfyg-spinner-btn-label {
      opacity: 0.0;
    }

    div.sfyg-spinner-btn-spinner {
      opacity: 1.0;
    }
  }

  &.sfyg-spinner-btn-loaded {
    div.sfyg-spinner-btn-label {
      opacity: 0.0;
    }

    div.sfyg-spinner-btn-spinner {
      opacity: 1.0;
    }
  }
}
